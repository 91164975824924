<script>
  /**
   * A half modal that opens from the bottom. Used for mobile.
   */
  export default {
    props: {
      /** The menu will open when this value is true. */
      open: {
        type: Boolean,
        default: false,
      },
      /** Will make menu almost full height (has margin at the top to leave room for the dismiss button).
       * Used in the Essentials Shop for the sub nav. */
      fullScreen: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      dismiss() {
        this.$emit('dismiss')
      },
    },
  }
</script>

<template>
  <section class="z-70 fixed top-0 mt-17">
    <transition name="overlay">
      <div v-if="open" class="fixed inset-0 opacity-80 bg-true-black" />
    </transition>
    <transition-group name="half-modal" tag="div">
      <template v-if="open">
        <aside
          key="aside"
          :class="`fixed inset-x-0 w-full ${
            fullScreen ? 'h-full bg-black overflow-y-auto' : 'bottom-0 shadow-xl bg-white'
          }`"
        >
          <div
            v-if="!fullScreen"
            class="absolute right-0 top-0 mr-2 -mt-10 bg-gray-light rounded-full"
          >
            <BaseIconClickable label="Dismiss" :size="4" :padding="2" @click="dismiss">
              <IconX />
            </BaseIconClickable>
          </div>
          <!------- Content goes here ------->
          <slot />
        </aside>
        <div
          v-if="fullScreen"
          key="div"
          class="z-90 fixed right-0 -mt-12 mr-2 bg-gray-light rounded-full"
        >
          <BaseIconClickable label="Dismiss" :size="4" :padding="2" @click="dismiss">
            <IconX />
          </BaseIconClickable>
        </div>
      </template>
    </transition-group>
  </section>
</template>

<style lang="postcss" scoped>
  .half-modal {
    &-enter-active,
    &-leave-active {
      @apply transform transition duration-400;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0 translate-y-full;
    }
  }

  .overlay {
    &-enter-active,
    &-leave-active {
      @apply transition duration-400;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0;
    }
  }
</style>
